import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import LinearProgress from '@fv-components/linear-progress';
import { AddInStorage } from './storage/AddInStorage';

const CONSOLE_LOGS_KEY = 'console_logs_history';
const MAX_LOGS = 50;

const Wrapper: FC<PropsWithChildren<{ enabled?: boolean }>> = ({ children, enabled = false }) => {
    const [ms, setMs] = useState({ ...AddInStorage.store });
    const [logs, setLogs] = useState<string[]>(() => {
        try {
            const savedLogs = localStorage.getItem(CONSOLE_LOGS_KEY);
            return savedLogs ? JSON.parse(savedLogs) : [];
        } catch (error) {
            console.error('Error loading logs from localStorage:', error);
            return [];
        }
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        AddInStorage.restoreDataFromLastKey();
        setLoading(false);
        return () => {
            AddInStorage.persistData();
        };
    }, []);

    // Save logs to localStorage whenever they change
    useEffect(() => {
        if (!enabled) return;

        try {
            localStorage.setItem(CONSOLE_LOGS_KEY, JSON.stringify(logs));
        } catch (error) {
            console.error('Error saving logs to localStorage:', error);
        }
    }, [logs, enabled]);

    useEffect(() => {
        if (!enabled) return;

        // Store the original console.log
        const originalConsoleLog = window.console.log;

        // Override console.log
        window.console.log = function (...args: unknown[]) {
            // Call the original console.log
            originalConsoleLog.call(console, ...args);

            // Add the log to our state, keeping only the latest MAX_LOGS
            setLogs((prevLogs) => {
                const newLog = args.map((arg) => typeof arg === 'object'
                    ? JSON.stringify(arg, null, 2) : String(arg)).join(' ');
                const updatedLogs = [...prevLogs, newLog];
                return updatedLogs.slice(-MAX_LOGS); // Keep only the latest MAX_LOGS
            });
        };

        const interval = window.setInterval(() => {
            const newMs = { ...AddInStorage.store };
            if (JSON.stringify(newMs) !== JSON.stringify(ms)) {
                setMs(newMs);
            }
        }, 1000);

        return () => {
            // Restore original console.log when component unmounts
            window.console.log = originalConsoleLog;
            window.clearInterval(interval);
        };
    }, [ms, enabled]);

    return (
        <div>
            {loading
                ? <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'white'
                }}>
                    <div style={{ width: '80%' }}>
                        <LinearProgress />
                    </div>
                </div>
                : children}
            {enabled && (
                <>
                    <pre style={{
                        background: '#f5f5f5',
                        padding: '1rem',
                        borderRadius: '4px',
                        overflow: 'auto',
                        maxHeight: '150px',
                        fontSize: '12px',
                        fontFamily: 'monospace',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                        margin: '1rem 0'
                    }}>
                        {JSON.stringify(ms, null, 2)}
                    </pre>
                    <div style={{ position: 'relative' }}>
                        <button
                            type="button"
                            onClick={() => {
                                localStorage.removeItem(CONSOLE_LOGS_KEY);
                                setLogs([]);
                            }}
                            style={{
                                position: 'absolute',
                                right: '0.5rem',
                                top: '-0.5rem',
                                zIndex: 1,
                                padding: '0.25rem 0.5rem',
                                fontSize: '11px',
                                backgroundColor: '#444',
                                color: '#fff',
                                border: 'none',
                                borderRadius: '3px',
                                cursor: 'pointer'
                            }}
                        >
                            Clear Logs
                        </button>
                        <pre style={{
                            background: '#2b2b2b',
                            color: '#ffffff',
                            padding: '1rem',
                            borderRadius: '4px',
                            overflow: 'auto',
                            maxHeight: '150px',
                            fontSize: '12px',
                            fontFamily: 'monospace',
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word',
                            margin: '1rem 0'
                        }}>
                            {logs.map((log, index) => (
                                <div key={`log_key_${index}`} style={{ marginBottom: '0.5rem' }}>
                                    {`> ${log}`}
                                </div>
                            ))}
                        </pre>
                    </div>
                </>
            )}
        </div>
    );
};

export default Wrapper;